// utils/API.js

import axios from "axios";
import PARAMS from "utils/PARAMS";
import { toast } from 'react-toastify';

// Instancia para api
var instance = axios.create({
  baseURL: PARAMS.apiUrl,
  timeout: 10000
});

instance.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("apiToken");
instance.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error.response) {
    switch (error.response.status) {
      case 401:
        if (localStorage.getItem("apiToken")) {
          toast("Sesión caducada", {type: "error"});
          axios.get('logout');
          localStorage.removeItem('apiToken');
          localStorage.removeItem('userType');
          window.location.replace(PARAMS.baseUrl);
        }else{
          // toast("Usuario no autorizado", {type: "error"});
        }
        break;
      case 404:
        toast("Url no disponible", {type: "error"});
        break;
      case 500:
        break;
      default:

    }
  }else{
    toast("No se ha podido establecer conexión", {type: "error"});
  }

  return Promise.reject(error);
});

// Instancia para api responseType blob
var instance_blob = axios.create({
  baseURL: PARAMS.apiUrl,
});
instance_blob.defaults.responseType = 'blob';
instance_blob.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("apiToken");
instance_blob.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (401 === error.response.status) {
    if (localStorage.getItem("apiToken")) {
      toast("Sesión caducada", {type: "error"});
      instance_blob.get('logout');
      localStorage.removeItem('apiToken');
      localStorage.removeItem('userType');
      window.location.replace(PARAMS.baseUrl);
    }else{
      // toast("Usuario no autorizado", {type: "error"});
    }
  } else {
    return Promise.reject(error);
  }
});

// Instancia para api formdata
var instance_archivo = axios.create({
  baseURL: PARAMS.apiUrl,
});
instance_archivo.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("apiToken");
instance_archivo.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (401 === error.response.status) {
    if (localStorage.getItem("apiToken")) {
      toast("Sesión caducada", {type: "error"});
      instance_archivo.get('logout');
      localStorage.removeItem('apiToken');
      localStorage.removeItem('userType');
      window.location.replace(PARAMS.baseUrl);
    }else{
      // toast("Usuario no autorizado", {type: "error"});
    }
  } else {
    return Promise.reject(error);
  }
});

// Funcion auxiliar async
async function resolve(promise) {
  const resolved = {
    data: null,
    error: null
  };

  try {
    resolved.data = await promise;
  } catch(e) {
    if (e.response && e.response.data && e.response.data.error) {
      resolved.error = e.response.data.error;
    }else{
      resolved.error = 'Se ha producido un error';
    }
  }

  return resolved;
}

// Ruta para verificar tipo de usuario (token)
export async function isAdminAuth() {
  return await resolve(
    instance.post('isAdminAuth').then(res => res.data)
  )
}

// Ruta para verificar tipo de usuario (no token)
export async function isLogin() {
  return await resolve(
    instance.post('isLogin').then(res => res.data)
  )
}

export async function login(email, password, userType) {
  return await resolve(
    instance.post('login', { email, password, userType }).then(res => res.data)
  )
}

export async function register(nombre, email, password, userType) {
  return await resolve(
    instance.post('register', { nombre, email, password, userType }).then(res => res.data)
  )
}

export async function resetPass(email) {
  return await resolve(
    instance.post('resetPassword/create', { email }).then(res => res.data)
  )
}

export async function logout() {
  return await resolve(
    instance.get('logout').then(res => res.data)
  )
}

export async function getUser() {
  return await resolve(
    instance.post('usuarios/show').then(res => res.data)
  )
}

export async function userIndex(search, page, perPageData) {
  return await resolve(
    instance.post('usuarios/index', { search, page, perPageData }).then(res => res.data)
  )
}

export async function userStore(nombre, email, password, apellidos, puesto, company) {
  return await resolve(
    instance.post('usuarios/store', { nombre, email, password, apellidos, puesto, company }).then(res => res.data)
  )
}

export async function userUpdate(id, nombre, email, password, apellidos, puesto, company) {
  return await resolve(
    instance.post(`usuarios/update/${id}`, { nombre, email, password, apellidos, puesto, company }).then(res => res.data)
  )
}

export async function userDestroy(id) {
  return await resolve(
    instance.post(`usuarios/destroy/${id}`).then(res => res.data)
  )
}

export async function logIndex() {
  return await resolve(
    instance.post('log/index').then(res => res.data)
  )
}

export async function convocatoriasIndex(findBy, page, perPageData) {
  return await resolve(
    instance.post('convocatorias/index', {findBy, page, perPageData}).then(res => res.data)
  )
}

export async function convocatoriaStore(convocatoria) {
  return await resolve(
    instance.post('convocatorias/store', convocatoria).then(res => res.data)
  )
}

export async function convocatoriaUpdate(convocatoria) {
  return await resolve(
    instance.post(`convocatorias/update/${convocatoria.id}`, convocatoria).then(res => res.data)
  )
}

export async function convocatoriaDestroy(id) {
  return await resolve(
    instance.post(`convocatorias/destroy/${id}`).then(res => res.data)
  )
}

export async function listConvocatorias() {
  return await resolve(
    instance.post(`convocatorias/list`).then(res => res.data)
  )
}

export async function variableShow(variable) {
  return await resolve(
    instance.post(`pregunta/show`, { variable }).then(res => res.data)
  )
}

export async function getRespuestas(findBy, page, perPageData, convocatoria_id) {
  return await resolve(
    instance.post(`respuesta/index`, { findBy, page, perPageData, convocatoria_id }).then(res => res.data)
  )
}

export async function respuestasExcel() {
  return await resolve(
    instance.post(`respuesta/excel`).then(res => res.data)
  )
}


export async function existeSubsanacion(id) {
  return await resolve(
    instance.post(`respuesta/existe_subsanacion`, {id: id}).then(res => res.data)
  )
}

export async function cargarVariables(variables, user_id, subsanacion = 'inicial', convocatoria= false) {
  return await resolve(
    instance.post(`respuesta/cargar`, { variables, user_id, subsanacion,convocatoria }).then(res => res.data)
  )
}

export async function guardarVariable(variable, value, orden = null, user_id = null, tipo = 'inicial', convocatoria) {
  return await resolve(
    instance.post("respuesta/guardar", {variable, value, orden, user_id, tipo,convocatoria}).then(res => res.data)
  )
}

export async function eliminarVariables(variable, orden, user_id = null) {
  return await resolve(
    instance.post("respuesta/eliminar", {variable, orden, user_id}).then(res => res.data)
  )
}

export async function guardarArchivo(file, variable,convocatoria) {
  let data = new FormData();
  data.append('file', file);
  data.append('variable', variable);
  data.append('convocatoria', convocatoria);
  let settings = { headers: { 'content-type': 'multipart/form-data' } }

  return await resolve(
    instance_archivo.post("guardarArchivo", data, settings).then(res => res.data)
  )
}

export async function obtenerArchivo(variable, user_id,convocatoria) {
  let data = new FormData();
  data.append('variable', variable);
  data.append('user_id', user_id);
  data.append('convocatoria', convocatoria);
  return await resolve(
    instance_archivo.post("obtenerArchivo", data).then(res => res.data)
  )
}

export async function borrarArchivo(variable, file_name,convocatoria) {
  let data = new FormData();
  data.append('nombre_archivo', file_name);
  data.append('variable', variable);
  data.append('convocatoria', convocatoria);
  return await resolve(
    instance_archivo.post("borrarArchivo", data).then(res => res.data)
  )
}

export async function importCodigos(input) {
  let data = new FormData();
  data.append('file', input);
  let settings = { headers: { 'content-type': 'multipart/form-data' } };

  return await resolve(
    instance_archivo.post("codigosUsuario/import", data).then(res => res.data)
  )
}

export async function getPaymentUrl(convocatoria_id) {
  return await resolve(
    instance.post("redsys/get_payment_url", { convocatoria_id }).then(res => res.data)
  )
}

export async function checkEstadoTasa(convocatoria_id) {
  return await resolve(
    instance.post("redsys/check_estado_tasa", { convocatoria_id }).then(res => res.data)
  )
}

export async function obtenerNombrePDF(tipo, user_id) {
  return await resolve(
    instance.post("pdf/obtenernombre", { tipo, user_id }).then(res => res.data)
  )
}

export async function getPDF(nombre, tipo) {

  let data = new FormData();
  data.append('nombrearchivo', nombre);

  return await resolve(
    instance_blob.post(`pdf/${tipo}`, data).then(res => res.data)
  )
}
export async function getPDFAutoliquidacion(convocatoria= false) {
  return await resolve(
    instance_blob.post(`pdf/get_autoliquidacion`, { convocatoria }).then(res => res.data)
  )
}
export async function descargarPDFConvocatoria(convocatoria= false, user_id = null) {
  return await resolve(
    instance_blob.post(`pdf/convocatoria`, { convocatoria, user_id }).then(res => res.data)
  )
}
export async function getConvocatorias(fecha){
  let data = new FormData();
  data.append('fecha', fecha);
  return await resolve(
    instance.post("respuesta/listado_convocatorias_disponibles", data).then(res => res.data)
  )
}
export async function getConvocatoriasIniciadas(fecha){
  let data = new FormData();
  return await resolve(
    instance.post("respuesta/listado_convocatorias_iniciadas", data).then(res => res.data)
  )
}
