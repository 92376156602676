import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
import People from "@material-ui/icons/People";
import LockIcon from '@material-ui/icons/Lock';
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import * as API from "utils/API_V2";
import { validateFields } from 'utils/GlobalFunctions';
import { toast } from 'react-toastify';

import styles from "assets/jss/material-kit-react/views/loginPage.js";

const useStyles = makeStyles(styles);

export default function Login(props) {
  const [nombre, setNombre] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [pass, setPass] = React.useState('');
  const [pass2, setPass2] = React.useState('');
  const [error, setError] = useState('');

  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function() {
    setCardAnimation("");
  }, 700);

  const register = async() => {
    if (pass == pass2) {
      var validate_fields = new Map([['nombre', nombre], ['email', email], ['contraseña', pass] ]);
      var validate = validateFields(validate_fields);
      setError(false);
      if (validate.status) {
        const res = await API.register(nombre, email, pass, 'front');
        if (res.error) {
          toast(res.error, {type: "warning"});
        }else{
          localStorage.setItem("apiToken", res.data.token);
          localStorage.setItem("userName", res.data.nombre);
          window.location.reload(false);
        }
      }else{
        toast(validate.message, {type: "warning"});
        setError(true);
      }
    }else{
      toast('Las contraseñas no coinciden', {type: "warning"});
    }
  };

  const classes = useStyles();
  return (
    <GridItem xs={12} sm={12} md={5}>
      <Card className={classes[cardAnimaton]}>
        <form className={classes.form}>
          <CardHeader color="custom" className={classes.cardHeader}>
            <h4>Registrarse</h4>
            <div className={classes.socialLine}>
              {/*<Button
                justIcon
                href="#pablo"
                target="_blank"
                color="transparent"
                onClick={e => e.preventDefault()}
              >
                <i className={"fab fa-twitter"} />
              </Button>
              <Button
                justIcon
                href="#pablo"
                target="_blank"
                color="transparent"
                onClick={e => e.preventDefault()}
              >
                <i className={"fab fa-facebook"} />
              </Button>
              <Button
                justIcon
                href="#pablo"
                target="_blank"
                color="transparent"
                onClick={e => e.preventDefault()}
              >
                <i className={"fab fa-google-plus-g"} />
              </Button>*/}
            </div>
          </CardHeader>
          <CardBody>
            <CustomInput
              labelText="Nombre"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                type: "text",
                defaultValue: nombre,
                onChange: (event) => {
                  setNombre(event.target.value);
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <People className={classes.inputIconsColor} />
                  </InputAdornment>
                ),
                error: (error && nombre == '') ? true : false
              }}
            />
            <CustomInput
              labelText="Email"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                type: "text",
                defaultValue: email,
                onChange: (event) => {
                  setEmail(event.target.value);
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <People className={classes.inputIconsColor} />
                  </InputAdornment>
                ),
                error: (error && email == '') ? true : false
              }}
            />
            <CustomInput
              labelText="Contraseña"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                type: "password",
                defaultValue: pass,
                onChange: (event) => {
                  setPass(event.target.value);
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <LockIcon className={classes.inputIconsColor} />
                  </InputAdornment>
                ),
                error: (error && pass == '') ? true : false
              }}
            />
            <CustomInput
              labelText="Repetir contraseña"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                type: "password",
                defaultValue: pass2,
                onChange: (event) => {
                  setPass2(event.target.value);
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <LockIcon className={classes.inputIconsColor} />
                  </InputAdornment>
                ),
                error: (error && pass2 == '') ? true : false
              }}
            />
          </CardBody>
          <CardFooter className={classes.cardFooter}>
            <Button color="custom" size="lg" onClick={() => register()}>
              Registrarse
            </Button>
            <Button color="custom" size="lg" onClick={() => props.goLogin()}>
              Volver al login
            </Button>
          </CardFooter>
        </form>
      </Card>
    </GridItem>
  );
}
